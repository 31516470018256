<template>
  <div class="carrossel-produtos-relacionados" ref="carrosselContainer">
    <div class="row">
      <div class="col-lg-12">
        <div class="titulo-bloco">
          <h2 class="block-title">PRODUTOS RELACIONADOS</h2>
          <div class="d-flex justify-content-end">
            <button
              class="btn btn-outline-primary btn-sm me-2"
              type="button"
              @click="changePage(currentPage - 1)"
              :disabled="loading || currentPage <= 1"
              aria-label="Move to previous slide"
            >
              <i class="fas fa-chevron-left"></i>
            </button>
            <button
              class="btn btn-outline-primary btn-sm"
              type="button"
              @click="changePage(currentPage + 1)"
              :disabled="loading || currentPage >= totalPages"
              aria-label="Move to next slide"
            >
              <i class="fas fa-chevron-right"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="product-list" :class="{ 'fade-in': isFadingIn, 'fade-out': isFadingOut }">
          <div v-if="loading" v-for="n in itemsPerPage" :key="n" class="product-item skeleton-item">
            <div class="conteudo-produto-unico conteudo-produto-relacionado">
              <div class="skeleton skeleton-image"></div>
              <div class="product-info">
                <div class="skeleton skeleton-text skeleton-name"></div>
                <div class="skeleton skeleton-text skeleton-price"></div>
                <div class="skeleton skeleton-text skeleton-code"></div>
              </div>
            </div>
          </div>
          <div v-else v-for="(produto, index) in paginatedProdutos" :key="index" class="product-item">
            <div class="conteudo-produto-unico conteudo-produto-relacionado">
              <div class="product-image-container">
                <a :href="`/produto/${produto.slug}`">
                  <img 
                    v-if="produto.imagens && produto.imagens.length > 0 && produto.imagens[0].url" 
                    :src="produto.imagens[0].url" 
                    class="img-fluid imagem-principal product-image" 
                    :alt="`Imagem do produto ${produto.nome}`"
                  />
                  <img 
                    v-else 
                    :src="SemImagem" 
                    class="img-fluid imagem-principal product-image" 
                    alt="Produto sem Imagem"
                  />
                </a>
              </div>
              <div class="product-info">
                <p class="product-name">{{ produto.nome }}</p>
                <p class="product-price">{{ formatarPreco(produto.valor_venda) }}</p>
                <p class="product-code">{{ produto.codigo }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>



<script setup>
import { ref, onMounted, onUnmounted, computed, watch } from 'vue';
import axios from 'axios';
import SemImagem from '../../assets/images/produto-sem-imagem.jpg'

const props = defineProps({
  marcaId: {
    type: Number,
    required: true
  }
});

const produtos = ref([]);
const produtosDuplicados = ref([]);
const currentPage = ref(1);
const itemsPerPage = ref(5);
const loading = ref(true);

const carrosselContainer = ref(null);

const updateItemsPerPage = () => {
  if (carrosselContainer.value) {
    const width = carrosselContainer.value.clientWidth;
    if (width >= 1200) {
      itemsPerPage.value = 5;
    } else if (width >= 992) {
      itemsPerPage.value = 4;
    } else if (width >= 768) {
      itemsPerPage.value = 3;
    } else {
      itemsPerPage.value = 2;
    }
  }
};

const fetchRelatedProducts = async () => {
  try {
    loading.value = true;
    if (props.marcaId) {
      const response = await axios.get(`https://api-genove.agcodecraft.com/api/public/products?brand_id=${props.marcaId}`);
      produtos.value = response.data.data;
      produtosDuplicados.value = [...produtos.value, ...produtos.value];
    }
  } catch (error) {
    console.error('Erro ao buscar produtos relacionados:', error);
  } finally {
    loading.value = false;
  }
};

const formatarPreco = (preco) => {
  const precoNumerico = parseFloat(preco);
  if (isNaN(precoNumerico)) return 'Preço inválido';
  
  return `U$ ${precoNumerico.toLocaleString('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })}`;
};
const paginatedProdutos = computed(() => {
  const startIndex = (currentPage.value - 1) * itemsPerPage.value;
  const endIndex = startIndex + itemsPerPage.value;
  return produtosDuplicados.value.slice(startIndex, endIndex);
});

const totalPages = computed(() => Math.ceil(produtosDuplicados.value.length / itemsPerPage.value));

const isFadingIn = ref(false);
const isFadingOut = ref(false);

const changePage = (pageNumber) => {
  if (!loading.value) {
    if (pageNumber < 1) {
      pageNumber = 1;
    } else if (pageNumber > totalPages.value) {
      pageNumber = totalPages.value;
    }

    if (pageNumber > currentPage.value) {
      isFadingIn.value = false;
      isFadingOut.value = true;
    } else if (pageNumber < currentPage.value) {
      isFadingIn.value = true;
      isFadingOut.value = false;
    }

    setTimeout(() => {
      currentPage.value = pageNumber;
      isFadingIn.value = false;
      isFadingOut.value = false;
    }, 300); // Tempo da animação de fade
  }
};


onMounted(() => {
  fetchRelatedProducts();
  updateItemsPerPage();

  // Observe changes in the size of the carrossel container
  const resizeObserver = new ResizeObserver(() => {
    updateItemsPerPage();
  });

  if (carrosselContainer.value) {
    resizeObserver.observe(carrosselContainer.value);
  }

  onUnmounted(() => {
    resizeObserver.disconnect();
  });
});

watch(() => props.marcaId, (newId) => {
  if (newId) {
    fetchRelatedProducts();
  }
});
</script>

<style scoped>
.fade-in {
  animation: fadeIn 0.5s ease-in-out forwards;
}

.fade-out {
  animation: fadeOut 0.5s ease-in-out forwards;
}
.animated-slide {
  transition: transform 0.3s ease;
}

.carrossel-produtos-relacionados {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
}

.titulo-bloco h2 {
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #333;
  justify-content: end;
}

.product-list {
  display: flex;
  overflow: hidden;
  padding: 10px 0;
  justify-content: center;
  position: relative;
  transition: transform 0.5s ease-in-out;
}
.slide-left {
  transform: translateX(-100%);
}

.slide-right {
  transform: translateX(100%);
}

.animated-slide {
  transition: transform 0.5s ease;
}
.product-item {
  flex: 0 0 auto;
  width: 250px;
  margin-right: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
}

.product-item:hover {
  transform: scale(1.03);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.imagem-produto {
  position: relative;
  overflow: hidden;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.imagem-produto img {
  width: 100%;
  height: auto;
  transition: opacity 0.3s;
}

.imagem-hover {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.imagem-produto:hover .imagem-hover {
  opacity: 1;
}

.nome-produto {
  padding: 15px;
  font-size: 1.2rem;
}

.nome-produto a {
  color: #333;
  text-decoration: none;
  transition: color 0.3s;
}

.nome-produto a:hover {
  color: #007bff;
}

.caixa-preco {
  padding: 0 15px 15px;
}

.caixa-preco h4 {
  font-size: 1.1rem;
  color: #333;
}

.product-item {
  border: 2px solid #e0ccad;
  border-radius: 12px;
  width: 100%;
  max-width: 260px;
  min-width: 220px;
  flex: 1;
  height: auto; 
  aspect-ratio: 4 / 7; 
  overflow: hidden;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  background-color: white;
}

.product-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

.product-image-container {
  width: 100%;
  height: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.product-image {
  width: auto;
  height: 100%;
  object-fit: cover;
  padding-top: 15px;
}

.product-info {
  height: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.product-name {
  font-size: 18px; 
  font-family: 'Lato', sans-serif;
  color: rgb(43, 43, 43);
  margin-bottom: 10px; 
  font-weight: 500; 
  line-height: 1.5; 
  margin-top: 20px;
}

.product-price {
  font-size: 28px; 
  font-family: 'Lato', sans-serif;
  color: #c99c5a; 
  margin: 5px 0; 
  font-weight: 700; 
  margin-top: 10px;
}

.product-code {
  font-size: 16px;
  color: #757575;
  line-height: 1.667; 
  margin-top: auto;
  margin-bottom: 15px;
}

.pagination-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.pagination-controls button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin: 0 10px;
}

.pagination-controls button:disabled {
  background-color: #d6d6d6;
  cursor: not-allowed;
}

.pagination-controls span {
  font-size: 16px;
  color: #333;
}
.btn-outline-primary {
  color: #777777;
  border-color: #777777;
}

.btn-outline-primary:hover {
  background-color: #C99C5A;
  color: white;
  border-color: #C99C5A;
}
.btn:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
.skeleton-item {
  background-color: #f5f5f5;
  border-radius: 10px;
  padding: 10px;
}

.skeleton {
  background-color: #e0e0e0;
  border-radius: 4px;
  margin-bottom: 10px;
}

.skeleton-image {
  width: 100%;
  height: 150px;
}

.skeleton-text {
  height: 20px;
}

.skeleton-name {
  width: 70%;
}

.skeleton-price {
  width: 50%;
}

.skeleton-code {
  width: 40%;
}
@media (max-width: 1200px) {
  .product-item {
    width: 200px;
  }
}

@media (max-width: 992px) {
  .product-item {
    width: 180px;
  }
}

@media (max-width: 768px) {
  .product-item {
    width: 150px;
  }
}

@media (max-width: 576px) {
  .product-item {
    width: 120px;
  }
}
</style>
