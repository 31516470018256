<template>
  <div class="container my-5 unique-carousel">
    <div class="d-flex justify-content-between align-items-center mb-4">
      <h2 class="mb-0">{{ title }}</h2>
      <div class="d-flex justify-content-end">
        <button
          class="btn btn-outline-primary btn-sm me-2"
          type="button"
          @click="left"
          aria-label="Move to previous slide"
          :disabled="loading || transitioning || !canNavigateManual || currentIndex === 0"
        >
          <i class="fas fa-chevron-left"></i>
        </button>
        <button
          class="btn btn-outline-primary btn-sm"
          type="button"
          @click="right"
          aria-label="Move to next slide"
          :disabled="loading || transitioning || !canNavigateManual || currentIndex === groupedProdutos.length - 1"
        >
          <i class="fas fa-chevron-right"></i>
        </button>
      </div>
    </div>

    <div class="linha-abaixo-titulo"></div>

    <div :id="carouselId" class="carousel slide">
      <div class="carousel-inner">
        <!-- Skeleton placeholder -->
        <div v-if="loading" class="carousel-item active skeleton-container">
          <div class="carousel-item-content">
            <div v-for="n in produtosPorSlide" :key="n" class="product-item skeleton">
              <div class="product-image-container">
                <div class="product-image skeleton-image"></div>
              </div>
              <div class="product-info">
                <p class="product-name skeleton-text"></p>
                <p class="product-price skeleton-text"></p>
                <p class="product-code skeleton-text"></p>
              </div>
            </div>
          </div>
        </div>

        <!-- Produtos reais -->
        <div
          v-else
          class="carousel-item"
          v-for="(group, index) in groupedProdutos"
          :key="index"
          :class="{ active: index === currentIndex }"
        >
          <div class="carousel-item-content">
            <router-link
              v-for="produto in group"
              :key="produto.slug"
              :to="`/produto/${produto.slug}`"
              class="product-item"
            >
              <div class="product-image-container">
                <img :src="produto.imagens && produto.imagens.length > 0 ? produto.imagens[0].url : semImagem" class="product-image" :alt="produto.nome" />
              </div>
              <div class="product-info">
                <p class="product-name">{{ produto.nome }}</p>
                <p class="product-price">{{ formatarPreco(produto.valor_venda) }}</p>
                <p class="product-code">{{ produto.codigo }}</p>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import axios from 'axios';
import { Carousel } from 'bootstrap';
import semImagem from '../../assets/images/produto-sem-imagem.jpg';
import { useWindowSize } from '@vueuse/core';

// Props para tornar cada carrossel único
const props = defineProps({
  title: {
    type: String,
    required: true
  },
  brandId: {
    type: Number,
    required: true
  },
  carouselId: {
    type: String,
    required: true
  }
});

const produtosDestaque = ref([]);
const loading = ref(true);
const transitioning = ref(false);
const canNavigateManual = ref(true); 
const { width } = useWindowSize();

const produtosPorSlide = computed(() => {
  if (width.value >= 1600) return 4;
  if (width.value >= 1200) return 3;
  if (width.value >= 768) return 2;
  return 1;
});

const groupedProdutos = computed(() => {
  const numProdutos = produtosPorSlide.value;
  return Array.from({ length: Math.ceil(produtosDestaque.value.length / numProdutos) }, (_, index) => 
    produtosDestaque.value.slice(index * numProdutos, (index + 1) * numProdutos)
  );
});

const currentIndex = ref(0);
let carouselInstance;
let autoPlayInterval;


const fetchFeaturedProducts = async () => {
  try {
    const response = await axios.get(`https://api-genove.agcodecraft.com/api/public/products?brand_id=${props.brandId}&tem_estoque=true&order_by=ordem&order=asc`);
    produtosDestaque.value = response.data.data;
  } catch (error) {
    console.error('Erro ao buscar produtos destacados:', error);
  } finally {
    loading.value = false;
  }
};

onMounted(() => {
  fetchFeaturedProducts();

  const carouselElement = document.getElementById(props.carouselId);
  carouselInstance = new Carousel(carouselElement, {
    interval: false,
    wrap: false,
    ride: false,
    slide: true
  });

  carouselElement.addEventListener('slide.bs.carousel', () => {
    transitioning.value = true;
    canNavigateManual.value = false;
  });

  carouselElement.addEventListener('slid.bs.carousel', () => {
    transitioning.value = false;
    canNavigateManual.value = true;
    updateButtonStates();
  });

  startAutoPlay();
});

const resetAutoPlay = () => {
  clearInterval(autoPlayInterval);
  startAutoPlay();
};

const left = () => {
  if (!loading.value && !transitioning.value) {
    transitioning.value = true;

    if (currentIndex.value > 0) {
      carouselInstance.prev();
      currentIndex.value = Math.max(currentIndex.value - 1, 0);
    } else {
      carouselInstance.to(groupedProdutos.value.length - 1);
      currentIndex.value = groupedProdutos.value.length - 1;
    }

    updateButtonStates();
    resetAutoPlay();
  }
};

const right = () => {
  if (!loading.value && !transitioning.value) {
    transitioning.value = true;

    if (currentIndex.value < groupedProdutos.value.length - 1) {
      carouselInstance.next();
      currentIndex.value = Math.min(currentIndex.value + 1, groupedProdutos.value.length - 1);
    } else {
      carouselInstance.to(0);
      currentIndex.value = 0;
    }

    updateButtonStates();
    resetAutoPlay();
  }
};

const startAutoPlay = () => {
  autoPlayInterval = setInterval(() => {
    if (!transitioning.value && canNavigateManual.value) {
      right();
    }
  }, 5000);
};

const updateButtonStates = () => {
  // Atualize os botões conforme a navegação
};

const formatarPreco = (preco) => {
  const precoNumerico = parseFloat(preco);
  if (isNaN(precoNumerico)) return 'Preço inválido';

  return `U$ ${precoNumerico.toLocaleString('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })}`;
};
</script>


<style scoped>
.unique-carousel h2 {
  font-size: 2rem;
  color: #C99C5A;
  font-weight: bold;
  font-family: 'Lato', sans-serif;
  
}

.unique-carousel .linha-abaixo-titulo {
  height: 4px;
  background-color: #e0e0e0;
  border-radius: 2px;
  width: 100%;
}

.unique-carousel .btn-outline-primary {
  color: #777777;
  border-color: #777777;
}

.unique-carousel .btn-outline-primary:hover {
  background-color: #C99C5A;
  color: white;
  border-color: #C99C5A;
}

.unique-carousel .carousel-item-content {
  display: flex;
  justify-content: center;
  gap: 15px;
  flex-wrap: nowrap;
  padding: 8px
}

.unique-carousel .product-item {
  border: 2px solid #e0ccad;
  border-radius: 12px;
  flex: 1 1 auto;
  max-width: 25%;
  min-width: 220px;
  height: auto;
  aspect-ratio: 4 / 7;
  overflow: hidden;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: white;
  margin-bottom: 15px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin-top: 10px;
  
}

.unique-carousel .product-item:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

.unique-carousel .product-image-container {
  width: 100%;
  height: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.unique-carousel .product-image {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.unique-carousel .product-info {
  height: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.unique-carousel .product-name {
  font-size: 1.2rem;
  font-family: 'Lato', sans-serif;
  color: rgb(43, 43, 43);
  margin-bottom: 10px;
  font-weight: 500;
  line-height: 1.5;
  margin-top: 20px;
}

.unique-carousel .product-price {
  font-size: 1.5rem;
  font-family: 'Lato', sans-serif;
  color: #C99C5A;
  margin: 5px 0;
  font-weight: 700;
  margin-top: 10px;
}

.unique-carousel .product-code {
  font-size: 0.9rem;
  color: #757575;
  line-height: 1.667;
  margin-top: auto;
  margin-bottom: 15px;
}

.unique-carousel .skeleton-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.unique-carousel .skeleton {
  animation: shimmer 1.5s infinite linear;
  background: linear-gradient(
    to right,
    #eeeeee 4%,
    #dddddd 25%,
    #eeeeee 36%
  );
  background-size: 1000px 100%;
  position: relative;
  overflow: hidden;
}

.unique-carousel .skeleton-image {
  width: 100%;
  height: 100%;
  background-color: #eeeeee;
}

.unique-carousel .skeleton-text {
  background-color: #eeeeee;
  height: 1rem;
  width: 70%;
  margin: 0 auto;
  border-radius: 4px;
}

@keyframes shimmer {
   0% {
     background-position: -1000px 0;
   }
   100% {
     background-position: 1000px 0;
   }
}
</style>
