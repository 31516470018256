<template>
  <div class="about-container">
    <div class="content-wrapper">
      <h1>SOBRE NÓS</h1>
      <p>
        Fundada com o propósito de oferecer uma experiência de compra excepcional, a Genove nasceu em Ciudad del Este como uma loja voltada para atender às necessidades de consumidores exigentes que buscam qualidade, praticidade e inovação. Desde o início, nossa missão tem sido trazer ao alcance de nossos clientes produtos originais e de alta qualidade, mantendo uma relação de confiança e excelência em atendimento.
      </p>
      <p>
        Desde os primeiros passos, a Genove conquistou espaço como uma loja de referência, oferecendo um portfólio diversificado que abrange desde perfumes e cosméticos das marcas mais desejadas do mercado até lançamentos tecnológicos das maiores marcas globais, como Samsung, Apple, Xiaomi e Honor. Nosso compromisso com a inovação garante que você sempre encontre os produtos mais recentes e tecnológicos em nossa loja.
      </p>
      <p>
        Além disso, expandimos continuamente nosso catálogo para incluir acessórios, produtos de cuidado pessoal e itens de beleza, priorizando sempre a qualidade e a autenticidade. Cada produto em nossas prateleiras é escolhido com o objetivo de atender aos mais altos padrões, garantindo a satisfação e a confiança de nossos clientes.
      </p>
      <p>
        Com preços extremamente competitivos e um atendimento que reflete nossos valores de respeito e proximidade, nos orgulhamos de ser uma escolha confiável para consumidores que desejam qualidade sem abrir mão da economia.
      </p>
      <p><strong>Genove – O melhor do mundo em suas mãos!</strong></p>
      <!-- Novo título e slider -->
      <h1>CONHEÇA NOSSA LOJA</h1>
      <div class="image-display">
       <div class="image-controls">
        <button class="nav-button left" @click="previousImage">&#8249;</button>
        <!-- Imagem principal -->
        <div class="main-image" @click="nextImage">
          <img :src="images[currentIndex]" alt="Imagem principal da loja" />
        </div>
        <button class="nav-button right" @click="nextImage">&#8250;</button>
       </div>
        <div class="thumbnails">
          <img
            v-for="(image, index) in images"
            :key="index"
            :src="image"
            class="thumbnail-image"
            alt="Miniatura da loja"
            @click="setMainImage(index)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'; // Adicione esta linha para importar o 'ref'

import img1 from '@/assets/images/loja/IMG1.jpg';
import img2 from '@/assets/images/loja/IMG2.jpg';
import img3 from '@/assets/images/loja/IMG3.jpg';
import img4 from '@/assets/images/loja/IMG4.jpg';
import img5 from '@/assets/images/loja/IMG5.jpg';

const images = [img1, img2, img3, img4, img5];
const currentIndex = ref(0); // Usando o 'ref' para tornar a variável reativa

// Função para alterar a imagem principal ao clicar na miniatura
const setMainImage = (index) => {
  currentIndex.value = index;
};

// Função para passar para a próxima imagem (slide)
const nextImage = () => {
  currentIndex.value = (currentIndex.value + 1) % images.length;
};

// Função para voltar para a imagem anterior (slide)
const previousImage = () => {
  currentIndex.value = (currentIndex.value - 1 + images.length) % images.length;
};
</script>

<style scoped>
.about-container {
  background-color: #f4f4f4;
  padding: 40px 0;
  display: flex;
  justify-content: center;
  min-height: 100vh;
}

.content-wrapper {
  background-color: #fff;
  border-radius: 15px;
  max-width: 1000px;
  padding: 40px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
}

h1 {
  color: #C99C5A;
  text-align: center;
}

h2 {
  color: #C99C5A;
  font-size: 1.5rem;
  margin-top: 40px;
  margin-bottom: 40px;
  text-align: center;
}

p {
  font-size: 1rem;
  margin-bottom: 10px;
  text-align: justify;
  color: #555;
}

strong {
  font-size: 1.1rem;
  color: #333;
}

.image-display {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.main-image {
  width: 100%;
  max-width: 600px;
  border-radius: 10px;
  box-shadow: 0 0 20px #D99D55;
  transition: transform 0.5s ease-in-out;
  cursor: pointer;
}
.image-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
}

.main-image img {
  width: 100%;
  border-radius: 10px;
}

.main-image img:hover {
  transform: scale(1.05);
}

.thumbnails {
  display: flex;
  justify-content: center;
  margin-top: 15px;
  gap: 10px;
}

.thumbnail-image {
  width: 100px;
  height: 75px;
  border-radius: 5px;
  box-shadow: 0 0 10px #D99D55;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.thumbnail-image:hover {
  transform: scale(1.1);
  box-shadow: 0 0 20px #D99D55;
}
h1 {
  margin-bottom: 40px;
}
p {
  color: black;
  font-size: 20px;
  margin-bottom: 20px;
  line-height: 1.2;
}
p strong {
  color: #333;
  text-align: center; 
  display: block; 
  width: 100%;
  margin-top: 40px;
  margin-bottom: 60px;
}
.nav-button {
  background: none;
  border: none;
  font-size: 4rem;
  color: #C99C5A;
  cursor: pointer;
  padding: 20px;
  transition: color 0.3s ease, transform 0.3s ease;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.nav-button.left {
  left: 50px;
}

.nav-button.right {
  right: 50px;
}

.nav-button:hover {
  color: #c2ac31;
}
/* Consultas de mídia para dispositivos móveis */
@media (max-width: 900px) {
  .nav-button {
    display: none; /* Remove os botões de navegação em telas pequenas */
  }
}
</style>
