<template>
  <footer class="dark-footer">
    <div class="container">
      <!-- navegação do rodapé -->
      <div class="footer-navigation section-padding">
        <div class="row">
          <div class="col-lg-4 col-md-4">
            <!-- descrição do rodapé -->
            <div class="footer-description">
              <div class="footer-logo">
                <img width="167" src="@/assets/images/logo-branca.png" alt="Logo">
              </div>
              <p>Avenida Carlos Antonio Lopez Ciudad del Este, 7000, Paraguai</p>
            </div>
            <!-- fim da descrição do rodapé -->
          </div>
          <div class="col-lg-8 col-md-8">
            <!-- links de navegação do rodapé -->
            <div class="row">
              <div class="col-lg-3 col-md-6" v-for="category in categories" :key="category.slug">
                <!-- bloco único de navegação do rodapé -->
                <div class="single-footer-nav-block">
                  <h2 class="block-title">
                    <a :href="`#`" @click.prevent="viewProducts(category.slug)">
                      {{ category.name }}
                    </a>
                  </h2>
                  <!-- Removido o bloco de departamentos -->
                </div>
                <!-- fim do bloco único de navegação do rodapé -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- fim da navegação do rodapé -->

      <!-- seção de direitos autorais -->
      <div class="copyright-section">
        <div class="copyright-container">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12">
              <p class="copyright-text text-center text-md-start">
                Copyright &copy; 2024 <a href="#">Genove</a>. Todos os Direitos Reservados
              </p>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
              <div class="payment-logos text-md-end text-center">
                <img width="100" src="@/assets/images/CodeCraft.png" alt="Logo CodeCraft">
              </div>
            </div>
          </div>
        </div>
        <!-- fim da seção de direitos autorais -->
      </div>
    </div>
  </footer>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import axios from 'axios';

export default {
  name: 'FooterContent',
  setup() {
    const categories = ref([]);
    const router = useRouter();

    const fetchCategories = async () => {
      try {
        const response = await axios.get('https://api-genove.agcodecraft.com/api/public/categories');
        categories.value = response.data;
      } catch (error) {
        console.error('Erro ao carregar categorias:', error);
      }
    };

    const viewProducts = (categorySlug) => {
      router.push({ name: 'produtos', params: { categoriaSlug: categorySlug } });
    };

    onMounted(() => {
      fetchCategories();
    });

    return { categories, viewProducts };
  }
};
</script>
<style scoped>
.dark-footer {
  background-color: #333;
  color: #fff;
}

.dark-footer a {
  color: #fff;
}

.dark-footer a:hover {
  color: #ddd;
}

.footer-navigation,
.copyright-section {
  padding: 20px 0;
}


/* Exibir categorias e departamentos corretamente em diferentes tamanhos de tela */
@media (max-width: 768px) {
  .footer-nav-links li.d-md-block {
    display: none;
  }

  .footer-nav-links li.d-block {
    display: block;
  }

  .footer-nav-links a {
    display: block;
    margin-bottom: 10px;
  }
}

@media (min-width: 769px) {
  .footer-nav-links li.d-md-none {
    display: none;
  }

  .footer-nav-links li.d-md-block {
    display: block;
  }

  .footer-nav-links a {
    display: block;
    margin-bottom: 5px;
  }
}
</style>
