<template>
    <div v-if="showImage" class="overlay" @click="closeImage">
      <div class="image-container" @click.stop>
        <a href="https://chat.whatsapp.com/H5DHoKN2dek7H17BLoeXPd" 
           target="_blank" 
           rel="noopener noreferrer" 
           @click="handleImageClick">
          <img src="@/assets/images/lista-whats.jpg" alt="Entre no grupo do WhatsApp" class="center-image" />
        </a>
        <button class="close-button" @click="closeImage">✖</button>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        showImage: false,
      };
    },
    created() {
      const lastShownTime = localStorage.getItem('popupLastShown');
      const currentTime = new Date().getTime();
      const showPopupDuration = 5 * 60 * 1000; 
  
      if (!lastShownTime || (currentTime - lastShownTime) > showPopupDuration) {
        this.showImage = true;
        localStorage.setItem('popupLastShown', currentTime); 
      }
    },
    methods: {
      closeImage() {
        this.showImage = false;
      },
      handleImageClick(event) {
        this.closeImage();
      },
    },
  };
  </script>
  
  <style>
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .image-container {
    position: relative; 
    width: 40%; /* Valor padrão para telas maiores */
    max-width: 600px;
    max-height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .center-image {
    width: 100%; /* A imagem ocupa 100% da largura do container */
    height: auto;
    cursor: pointer;
  }
  
  .close-button {
    position: absolute;
    top: -30px; 
    right: -35px; 
    background: none;
    border: none;
    color: red; 
    font-size: 24px; 
    cursor: pointer;
    z-index: 1001; 
  }
  
  /* Consultas de mídia para telas menores */
  @media (max-width: 768px) {
    .image-container {
      width: 80%; /* Aumenta a largura em telas menores */
    }
  }
  
  @media (max-width: 480px) {
    .image-container {
      width: 90%; /* Ainda maior em telas pequenas */
    }
  }
  </style>
  