// store/index.js
import { createStore } from 'vuex';
import axios from 'axios';

const store = createStore({
  state() {
    return {
      routeHistory: [],
      categories: [] // Novo estado para armazenar categorias
    };
  },
  mutations: {
    SET_CATEGORIES(state, categories) {
      state.categories = categories;
      localStorage.setItem('categories', JSON.stringify(categories));
    }
  },
  getters: {
    findCategoryBySlug: (state) => (slug) => {
      return state.categories.find(category => category.slug === slug);
    },
    findDepartmentBySlug: (state) => (slug) => {
      return state.categories
        .flatMap(category => category.departments || [])
        .find(department => department.slug === slug);
    }
  },
  
  actions: {
    addRoute({ commit }, route) {
      commit('ADD_ROUTE', route);
    },
    clearHistory({ commit }) {
      commit('CLEAR_HISTORY');
    },
    async fetchCategories({ commit }) {
      try {
        const response = await axios.get('https://api-genove.agcodecraft.com/api/public/categories');
        const categories = response.data;
        commit('SET_CATEGORIES', categories);
      } catch (error) {
        console.error('Erro ao buscar categorias:', error);
      }
    }
  },
  getters: {
    getCategories(state) {
      return state.categories;
    }
  }
});

export default store;
