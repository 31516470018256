<template>
  <div id="app">
    <header>
      <HeaderTopnav />
      <HeaderContent />
      <HeaderNavigation v-if="isDesktop" />
      <MobileMenu
        v-if="!isDesktop"
        :menuOpen="mobileMenuOpen"
        @toggle-menu="toggleMobileMenu"
      />
      <button
        v-if="!isDesktop && !mobileMenuOpen"
        @click="toggleMobileMenu"
        class="hamburger-btn"
      >
        <font-awesome-icon :icon="['fas', 'bars']" />
      </button>
    </header>

    <router-view />

    <FooterContent />
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faBars, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import HeaderTopnav from './components/template/header-topnav.vue';
import HeaderContent from './components/template/header-content.vue';
import HeaderNavigation from './components/template/header-navigation.vue';
import FooterContent from './components/template/footer-content.vue';
import MobileMenu from './components/template/mobile-navigation.vue';

// Adicionando os ícones ao library
library.add(faBars, faChevronLeft);

export default {
  name: 'App',
  components: {
    HeaderTopnav,
    HeaderContent,
    HeaderNavigation,
    FooterContent,
    MobileMenu,
    FontAwesomeIcon
  },
  setup() {
    const store = useStore();
    const isDesktop = ref(window.innerWidth > 991);
    const mobileMenuOpen = ref(false);
    const updateInterval = 3 * 60 * 1000;
    let intervalId;

    const startUpdating = () => {
      intervalId = setInterval(() => {
        store.dispatch('fetchProducts');
        store.dispatch('fetchCategories');
      }, updateInterval);
    };

    const handleResize = () => {
      isDesktop.value = window.innerWidth > 991;
    };

    const toggleMobileMenu = () => {
      mobileMenuOpen.value = !mobileMenuOpen.value;
    };

    onMounted(() => {
      startUpdating();
      window.addEventListener('resize', handleResize);
      handleResize(); // Verifica a largura da tela ao montar
    });

    onUnmounted(() => {
      clearInterval(intervalId);
      window.removeEventListener('resize', handleResize);
    });

    return {
      isDesktop,
      mobileMenuOpen,
      toggleMobileMenu
    };
  }
}
</script>

<style scoped>
.hamburger-btn {
  position: absolute; 
  top: 50px; 
  left: 10px; 
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  padding: 0;
  font-size: 24px;
  color: #333;
  z-index: 1100;
  transition: color 0.3s;
}

.hamburger-btn:hover {
  color: #C99C5A;
}

.mobile-menu {
  position: fixed;
  top: 0;
  right: 0;
  width: 90%; 
  height: 100%;
  background-color: #fff; 
  box-shadow: -2px 0 5px rgba(0,0,0,0.5); 
  transform: translateX(100%);
  transition: transform 0.3s ease; 
  z-index: 1000; 
}

.mobile-menu.open {
  transform: translateX(0); 
}

.mobile-menu .close-btn {
  position: absolute;
  top: 10px;
  left: 10px; 
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  padding: 0;
  font-size: 24px;
  color: #333;
  z-index: 1100;
  transition: color 0.3s;
}

.mobile-menu .close-btn:hover {
  color: #C99C5A;
}
</style>
